export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/miscellaneous/NotFound.vue'),
    meta: {
      permission: 'auth.login',
      layout: 'full',
    },
  },
  {
    path: '/suspended',
    name: 'suspended',
    component: () => import('@/views/pages/miscellaneous/Suspended.vue'),
    meta: {
      permission: 'auth.login',
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      permission: 'auth.login',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      permission: 'auth.reset',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/not-setup',
    name: 'misc-not-setup',
    component: () => import('@/views/pages/miscellaneous/NotSetup.vue'),
    meta: {
      permission: 'auth.login',
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      permission: 'auth.login',
      layout: 'full',
    },
  },

  {
    path: '/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      permission: 'user.self.update',
      pageTitle: 'Account Settings',
      breadcrumb: [{ text: 'Account Settings', active: true }],
    },
  },
];

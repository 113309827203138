import axios from '@axios';

class _Ability {
  lastCheckAt = 0;
  /**
   */
  constructor(options = { fetch }) {
    this.defaultPermissions = ['auth.login'];
    this.permissions = this.loadPermissions(options.permissions);

    if (options.fetch) {
      setTimeout(() => this.fetchPermissions().catch((error) => console.error('Failed to retrieve permissions', error)), 0);
    }
  }

  async fetchPermissions() {
    if (this.permissions.length > this.defaultPermissions.length && Date.now() - this.lastCheckAt < 1000) {
      return;
    }

    this.lastCheckAt = Date.now();
    // TODO: possibly move this else where so we can fetch entire user object rathern thatn soley their permissions
    const { data: permissions } = await axios.get(`v1/auth/permissions?t=${Date.now()}`);
    this.setPermissions(permissions);
  }

  async wcan(permission) {
    if (this.can(permission)) return true;
    return this.fetchPermissions()
      .then(() => this.can(permission))
      .catch(() => false);
  }

  can(permission) {
    // whitelist for absolute perms (no need for ! check, includes applies that)
    if (this.defaultPermissions.includes(permission)) {
      return true;
    }

    for (const node of this.permissions) {
      if (new RegExp(`^${node.replace(/\*/g, '.*')}$`).test(permission)) {
        return true;
      }
    }

    return false;
  }

  loadPermissions(dfl) {
    if (localStorage.getItem('$perm')) {
      return JSON.parse(localStorage.getItem('$perm'));
    }

    if (Array.isArray(dfl) && dfl.length > 1) {
      return dfl;
    }

    // default permissions
    return ['auth.login'];
  }

  setPermissions(perms, override = false) {
    if (!Array.isArray(perms)) {
      return;
    }

    if (perms.length < this.permissions.length && override !== true) {
      // If the perms we're applying has a shorter total perms list-pending, don't apply the changes
      // FIXME: We need a more elegant solution but this will temp fix some issues
      return;
    }

    console.log('setting perms to', perms);

    this.permissions = perms;
    localStorage.setItem('$perm', JSON.stringify(this.permissions));
  }

  clearPermissions() {
    this.setPermissions(['auth.login']);
  }
}

const $acl = new _Ability({ fetch: true });
window.$perm = $acl;

export default $acl;

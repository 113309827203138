import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    mergeStudents(ctx, { originId, targetId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('v1/students/merge', { originId, targetId })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProfiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('v1/students/profiles', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStudents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('v1/students', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStudent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/students/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStudentProfile(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/students/${id}/profile`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStudentProfileMondayNotes(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/integration/monday/comments?studentId=${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStudentSessions(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/students/${id}/sessions`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStudentSections(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/students/${id}/sections`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStudentDocuments(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/students/${id}/documents`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    resetStudentMyIPPassword(ctx, { studentId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v2/students/${studentId}/magic-link/password-reset`, {})
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    sendStudentMyIPOnboarding(ctx, { studentId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v2/students/${studentId}/magic-link/onboarding`, {})
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    sendStudentMyIPLogin(ctx, { studentId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v2/students/${studentId}/magic-link/login`, {})
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    sendStudentAthenaLogin(ctx, { studentId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v2/students/${studentId}/magic-link/athena`, {})
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    uploadStudentDocument(ctx, { studentId, documentId, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/students/${studentId}/documents/${documentId}/upload`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadStudentDocument(ctx, { studentId, documentId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/students/${studentId}/documents/${documentId}/upload`, { params: queryParams, responseType: 'blob' })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    documentStudentDocumentPreview(ctx, { studentId, documentId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/students/${studentId}/documents/${documentId}/embed`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadStudentDocumentProof(ctx, { studentId, documentId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/students/${studentId}/documents/${documentId}/proof`, { params: queryParams, responseType: 'blob' })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    previewStudentDocument(ctx, { studentId, documentId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/students/${studentId}/documents/${documentId}/preview`, { params: queryParams, responseType: 'blob' })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createStudentDocument(ctx, { studentId, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/students/${studentId}/documents`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    deleteStudentDocument(ctx, { studentId, documentId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`v1/students/${studentId}/documents/${documentId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStudentDocument(ctx, { studentId, documentId, documentData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete documentData.id;
      delete documentData.createdAt;
      delete documentData.updatedAt;
      delete documentData.references;
      delete documentData.downloadSize;
      delete documentData.student;
      delete documentData.tfv;

      return new Promise((resolve, reject) => {
        axios
          .patch(`v1/students/${studentId}/documents/${documentId}`, documentData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addStudentDocumentReference(ctx, { studentId, documentId, referenceData: { profileId } }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request

      return new Promise((resolve, reject) => {
        axios
          .post(`v1/students/${studentId}/documents/${documentId}/references`, { profileId })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addStudent(ctx, studentData) {
      return new Promise((resolve, reject) => {
        axios
          .post('v1/students', studentData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStudent(ctx, { id, studentData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete studentData.id;
      delete studentData.createdAt;
      delete studentData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`v1/students/${id}`, studentData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStudentProfile(ctx, { id, profileData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete profileData.id;
      delete profileData.createdAt;
      delete profileData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`v1/students/${id}/profile`, profileData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};

<template>
  <div>
    <GlobalSearch />
  </div>
</template>

<script>
import GlobalSearch from './GlobalSearch.vue';
import { ref } from '@vue/composition-api';

export default {
  components: {
    GlobalSearch,
  },

  methods: {
    isMacPlatform() {
      return navigator.platform.toUpperCase().includes('MAC');
    },

    isInputFocused() {
      const activeElement = document.activeElement;
      return (activeElement && ['INPUT', 'TEXTAREA'].includes(activeElement.tagName)) || activeElement.isContentEditable;
    },

    executeActionForKeySequence(keySequence) {
      const actionMap = {
        GS: () => this.navigateTo('/students'),
        GC: () => this.navigateTo('/roster/courses'),
        GP: () => this.navigateTo('/roster/pathways'),
        GE: () => this.navigateTo('/roster/profiles'),
        GX: () => this.navigateTo('/roster/courses/sections'),
        GO: () => this.navigateTo('/roster/pathways/cohorts'),
      };

      actionMap[keySequence]?.();
    },

    navigateTo(route) {
      // Check if the target route is the same as the current route
      if (this.$route.path !== route) {
        console.log(`Navigating to ${route}`);
        this.$router.push(route);
      } else {
        console.log('Already on the page:', route);
      }
    },

    handleKeyDown(e) {
      const isMac = this.isMacPlatform();
      const pressedKey = e.key.toLowerCase();
      const commandKeyPressed = isMac ? (e.metaKey ? 'cmd' : '') : e.ctrlKey ? 'cmd' : '';

      if (!this.isInputFocused()) {
        if (pressedKey === 'n') {
          e.preventDefault();
          this.$root.$emit('fireRootEventN');
        }

        if (pressedKey === 'g' && !this.gKeyPressTimer) {
          this.gKeyPressTimer = setTimeout(() => {
            this.gKeyPressTimer = null;
          }, 1500);
        } else if (this.gKeyPressTimer && pressedKey !== 'g') {
          const keySequence = `G${pressedKey.toUpperCase()}`;
          clearTimeout(this.gKeyPressTimer);
          this.gKeyPressTimer = null;
          e.preventDefault();
          this.executeActionForKeySequence(keySequence);
        }

        // Handle Global Search Toggle for 'cmd + k'
        if (pressedKey === 'k' && commandKeyPressed) {
          e.preventDefault();
          this.$root.$emit('openGlobalSearch');
        }
      }

      // Update pressedKeys
      if (!this.pressedKeys.includes(pressedKey)) {
        this.pressedKeys.push(pressedKey);
      }
    },

    handleKeyUp() {
      this.pressedKeys = [];
    },
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);

    if (this.gKeyPressTimer) {
      clearTimeout(this.gKeyPressTimer);
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);

    if (this.gKeyPressTimer) {
      clearTimeout(this.gKeyPressTimer);
    }
  },
  setup() {
    const pressedKeys = ref([]);
    const gKeyPressTimer = ref(null); // Timer to handle 'G' keypress sequence

    return {
      pressedKeys,
      gKeyPressTimer,
    };
  },
};
</script>
./GlobalSearch.vue

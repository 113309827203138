import jwt from 'jsonwebtoken';

import jwtDefaultConfig from './jwtDefaultConfig';

import { Controller } from '../../ws/ws';
import router from '@/router';
import ability from '@/libs/acl/ability';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };
    this.setToken(this.getToken());

    if (this.isAccessExpired()) {
      this.doTokenRefresh();
    }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getTokenAdv();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;

            this.doTokenRefresh()
              .then((r) => {
                this.isAlreadyFetchingAccessToken = false;
              })
              .catch((err) => {
                this.isAlreadyFetchingAccessToken = false;
              });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      },
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  isAccessExpired() {
    const token = this.getToken();
    if (!token || token === 'null') return false;

    const j = jwt.decode(token, {});
    return j.exp < Date.now() / 1000;
  }

  getTokenAdv() {
    if (!this.getToken() || this.getToken() === 'null') return null;

    if (this.isAccessExpired()) {
      return null;
    }

    return this.getToken();
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);

    if (window.WsController) {
      window.WsController.disconnect();
    }

    window.WsController = new Controller({
      url: process.env.VUE_APP_WS_URL || 'https://mgmt-api.prod.ipoint.services',
      token: value,
    });
  }

  clearTokens() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
    localStorage.removeItem('userData');
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotPassword, ...args);
  }

  resetPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPassword, ...args);
  }

  retrieveMfa(email) {
    return this.axiosIns.get(`${this.jwtConfig.retrieveMfaMethods}?email=${email}`);
  }

  requestMfaCode(...args) {
    return this.axiosIns.post(this.jwtConfig.requestMfaCode, ...args);
  }

  createMfaMethod(...args) {
    return this.axiosIns.post(this.jwtConfig.createMfaMethod, ...args);
  }

  enableMfaMethod(...args) {
    return this.axiosIns.post(this.jwtConfig.enableMfaMethod, ...args);
  }

  disableMfaMethod(...args) {
    return this.axiosIns.post(this.jwtConfig.disableMfaMethod, ...args);
  }

  deleteMfaMethod(...args) {
    return this.axiosIns.delete(this.jwtConfig.deleteMfaMethod, ...args);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  logout() {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);

    // Remove userData from localStorage
    localStorage.removeItem('userData');

    // Reset ability
    ability.clearPermissions();

    // Redirect to login page
    return router.push({ name: 'auth-login' });
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }

  doTokenRefresh() {
    return this.refreshToken()
      .then((r) => {
        const { access, refresh } = r.data;
        this.setToken(access.token);
        this.setRefreshToken(refresh.token);

        this.onAccessTokenFetched(this.getToken());
      })
      .catch((err) => {
        console.error('Failed to refresh token', err);
        return router.push({ name: 'auth-login' });
      });
  }
}

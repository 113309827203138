import axios from '@axios';

const fetchRoomList = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/sites/rooms', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchRoom = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/sites/rooms/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchRoomWaitlist = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/sites/rooms/${id}/waitlist`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const deleteRoom = (ctx, { id }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/sites/rooms/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createRoom = (ctx, { roomData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v2/sites/rooms', roomData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateRoom = (ctx, { roomId, roomData }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  delete roomData.id;
  delete roomData.createdAt;
  delete roomData.updatedAt;

  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/sites/rooms/${roomId}`, roomData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  fetchRoomList,
  fetchRoom,
  fetchRoomWaitlist,
  deleteRoom,
  createRoom,
  updateRoom,
};

export default {
  // Endpoints
  loginEndpoint: '/v1/auth/login',
  registerEndpoint: '/v1/auth/register',
  refreshEndpoint: '/v1/auth/refresh-tokens',
  logoutEndpoint: '/v1/auth/logout',
  forgotPassword: '/v1/auth/forgot-password',
  resetPassword: '/v1/auth/reset-password',
  retrieveMfaMethods: '/v1/auth/mfa-methods',
  createMfaMethod: '/v1/auth/mfa-methods',
  disableMfaMethod: '/v1/auth/mfa-methods/disable',
  enableMfaMethod: '/v1/auth/mfa-methods/enable',
  deleteMfaMethod: '/v1/auth/mfa-methods',
  requestMfaCode: '/v1/auth/mfa-methods/send',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: '$accessToken',
  storageRefreshTokenKeyName: '$refreshToken',
};

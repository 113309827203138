import axios from '@axios';

const fetchBuildingList = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/sites/buildings', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchBuilding = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/sites/buildings/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchBuildingWaitlist = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/sites/buildings/${id}/waitlist`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const deleteBuilding = (ctx, { id }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/sites/buildings/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createBuilding = (ctx, { buildingData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v2/sites/buildings', buildingData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateBuilding = (ctx, { buildingId, buildingData }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  delete buildingData.id;
  delete buildingData.createdAt;
  delete buildingData.updatedAt;

  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/sites/buildings/${buildingId}`, buildingData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  fetchBuildingList,
  fetchBuilding,
  fetchBuildingWaitlist,
  deleteBuilding,
  createBuilding,
  updateBuilding,
};

import buildingActions from './actions/buildingActions';
import campusActions from './actions/campusActions';
import roomActions from './actions/roomActions';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ...buildingActions,
    ...campusActions,
    ...roomActions,
  },
};

export default [
  // *===============================================---*
  // *--------- AUDIT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/system/audit',
    name: 'apps-audit-audit-list',
    component: () => import('@/views/apps/audit/audit-list/AuditList.vue'),

    meta: {
      permission: 'audit.audit.list',
      pageTitle: 'View Audit Log',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Audit Log',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),

    meta: {
      permission: 'user.list',
      pageTitle: 'Staff Members',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Staff Members',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),

    meta: {
      permission: 'user.get',
      pageTitle: 'View Staff Member',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Staff Members',
          to: '/users',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:id/edit',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),

    meta: {
      permission: 'user.update',
      pageTitle: 'Edit User',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Staff Members',
          to: '/users',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- STUDENT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/students',
    name: 'apps-students-list',
    component: () => import('@/views/apps/student/students-list/StudentsListToggle'),

    meta: {
      permission: 'student.list',
      pageTitle: 'List Students',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Students',
          active: true,
        },
      ],
    },
  },
  {
    path: '/students/blacklist',
    name: 'apps-students-blacklist',
    component: () => import('@/views/apps/student/students-blacklist/StudentsBlacklist'),

    meta: {
      permission: 'student.blacklist.list',
      pageTitle: 'Blacklisted Students',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Students',
          to: '/students',
        },
        {
          text: 'Blacklisted',
          active: true,
        },
      ],
    },
  },
  {
    path: '/students/:id',
    name: 'apps-students-view',
    component: () => import('@/views/apps/student/students-view/StudentsView.vue'),
    meta: {
      permission: 'student.get',
      pageTitle: 'View Student',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Students',
          to: '/students',
        },
        {
          text: 'View Student',
          active: true,
        },
      ],
    },
  },
  {
    path: '/students/:id/edit',
    name: 'apps-students-edit',
    component: () => import('@/views/apps/student/students-edit/StudentsEdit'),

    meta: {
      permission: 'student.update',
      pageTitle: 'Edit Student',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Students',
          to: '/students',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Job
  {
    path: '/jobs',
    name: 'apps-job-list',
    component: () => import('@/views/apps/job/jobs-list/JobsList'),
    meta: {
      permission: 'export.list',
      pageTitle: 'Jobs List',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Jobs',
          active: true,
        },
      ],
    },
  },
  /**
   * ACCOUNTING
   */
  {
    path: '/accounting/receivable/reconcile',
    name: 'apps-accounting-receivable-reconcile',
    component: () => import('@/views/apps/accounting/receivable/reconcile/Reconcile'),

    meta: {
      permission: 'accounting.receivable.reconcile',
      pageTitle: 'Reconciliation Wizard',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Reconciliation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/accounts',
    name: 'apps-accounting-receivable-account-list',
    component: () => import('@/views/apps/accounting/receivable/account/list/AccountList'),

    meta: {
      permission: 'accounting.receivable.account.list',
      pageTitle: 'Accounts List',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },

        {
          text: 'AR Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/accounts/:id',
    name: 'apps-accounting-receivable-account-view',
    component: () => import('@/views/apps/accounting/receivable/account/view/AccountView'),

    meta: {
      permission: 'accounting.receivable.account.get',
      pageTitle: 'View Account',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Accounts',
          to: '/accounting/receivable/accounts',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/accounts/:id/edit',
    name: 'apps-accounting-receivable-account-edit',
    component: () => import('@/views/apps/accounting/receivable/account/edit/AccountEdit'),

    meta: {
      permission: 'accounting.receivable.account.update',
      pageTitle: 'Edit Account',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Accounts',
          to: '/accounting/receivable/accounts',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/templates',
    name: 'apps-accounting-receivable-template-list',
    component: () => import('@/views/apps/accounting/receivable/template/list/TemplateList'),

    meta: {
      permission: 'accounting.receivable.template.list',
      pageTitle: 'Templates List',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Templates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/templates/:id/edit',
    name: 'apps-accounting-receivable-template-edit',
    component: () => import('@/views/apps/accounting/receivable/template/edit/TemplateEdit'),

    meta: {
      permission: 'accounting.receivable.template.update',
      pageTitle: 'Edit Template ',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Templates',
          to: '/accounting/receivable/templates',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/cohorts',
    name: 'apps-accounting-receivable-cohort-list',
    component: () => import('@/views/apps/accounting/receivable/cohort/list/CohortList'),

    meta: {
      permission: 'accounting.receivable.cohort.list',
      pageTitle: 'Cohorts List',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Cohorts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/cohorts/sheet',
    name: 'apps-accounting-receivable-cohort-import',
    component: () => import('@/views/apps/accounting/receivable/cohort/import/CohortImport'),

    meta: {
      permission: 'ui.accounting.receivable.cohort.sheet',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Cohorts',
          to: '/accounting/receivable/cohorts',
        },
        {
          text: 'Import',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/cohorts/:id',
    name: 'apps-accounting-receivable-cohort-view',
    component: () => import('@/views/apps/accounting/receivable/cohort/view/CohortsView'),

    meta: {
      permission: 'accounting.receivable.cohort.get',
      pageTitle: 'View Cohort',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Cohorts',
          to: '/accounting/receivable/cohorts',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/receivable/cohorts/:id/edit',
    name: 'apps-accounting-receivable-cohort-edit',
    component: () => import('@/views/apps/accounting/receivable/cohort/edit/CohortsEdit'),

    meta: {
      permission: 'accounting.receivable.cohort.update',
      pageTitle: 'Edit Cohort',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Cohorts',
          to: '/accounting/receivable/cohorts',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/jobs/:id',
  //   name: 'apps-invoice-preview',
  //   component: () => import('@/views/apps/job/invoice-preview/InvoicePreview.vue'),
  // },
  // Report
  {
    path: '/reports',
    name: 'apps-report-list',
    component: () => import('@/views/apps/report/report-list/ReportList.vue'),

    meta: {
      permission: 'report.list',
      pageTitle: 'Financial Reports List',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Reports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/import',
    name: 'apps-report-import',
    component: () => import('@/views/apps/report/report-import/ReportImport'),

    meta: {
      permission: 'report.import',
      pageTitle: 'Import Financial Reports',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'AR Reports',
          to: '/reports',
        },
        {
          text: 'Import',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/create',
    name: 'apps-report-add',
    component: () => import('@/views/apps/report/report-add/ReportAdd.vue'),

    meta: { permission: 'report.create', pageTitle: 'Create Financial Report' },
    breadcrumb: [
      {
        text: 'Management',
        to: '/students',
      },
      {
        text: 'Financial Reports',
        to: '/reports',
      },
      {
        text: 'Create',
        active: true,
      },
    ],
  },
  {
    path: '/reports/:id/preview',
    name: 'apps-report-preview',
    component: () => import('@/views/apps/report/report-preview/ReportPreview.vue'),

    meta: {
      permission: 'report.render',
      pageTitle: 'Preview Financial Report',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Financial Reports',
          to: '/reports',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/:id/edit',
    name: 'apps-report-edit',
    component: () => import('@/views/apps/report/report-edit/ReportEdit.vue'),

    meta: {
      permission: 'report.update',
      pageTitle: 'Edit Financial Report',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Financial Reports',
          to: '/reports',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  //////////// PLACEMENT SYSTEM

  {
    path: '/placement/monday/templates',
    name: 'apps-placement-monday-template-list',
    component: () => import('@/views/apps/placement/monday-template-list/MondayTemplateList'),

    meta: {
      permission: 'integrations.monday.template.list',
      pageTitle: 'Templates List',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Placement',
        },
        {
          text: 'Monday Templates',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- SITES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sites/campuses',
    name: 'apps-sites-campus-list',
    component: () => import('@/views/apps/site/campus/list/CampusList'),

    meta: {
      permission: 'site.campus.list',
      pageTitle: 'List Campuses',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Campuses',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sites/campus/:id',
    name: 'apps-sites-campus-view',
    component: () => import('@/views/apps/site/campus/view/CampusView.vue'),

    meta: {
      permission: 'site.campus.get',
      pageTitle: 'View Campus',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Campuses',
          to: '/sites/campuses',
        },
        {
          text: 'View Campus',
          active: true,
        },
      ],
    },
  },

  {
    path: '/sites/buildings',
    name: 'apps-sites-building-list',
    component: () => import('@/views/apps/site/building/list/BuildingList'),

    meta: {
      permission: 'site.building.list',
      pageTitle: 'List Buildings',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Buildings',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/sites/building/:id',
  //   name: 'apps-sites-building-view',
  //   component: () => import('@/views/apps/site/building/view/BuildingView.vue'),

  //   meta: {
  //     permission: 'site.building.get',
  //     pageTitle: 'View Site',
  //     breadcrumb: [
  //       {
  //         text: 'Management',
  //         to: '/students',
  //       },
  //       {
  //         text: 'Buildings',
  //         to: '/sites/buildings',
  //       },
  //       {
  //         text: 'View Building',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  {
    path: '/sites/rooms',
    name: 'apps-sites-room-list',
    component: () => import('@/views/apps/site/room/list/RoomList'),

    meta: {
      permission: 'site.room.list',
      pageTitle: 'List Rooms',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Rooms',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sites/room/:id',
    name: 'apps-sites-room-view',
    component: () => import('@/views/apps/site/campus/view/CampusView.vue'),

    meta: {
      permission: 'site.room.get',
      pageTitle: 'View Room',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Rooms',
          to: '/sites/rooms',
        },
        {
          text: 'View Room',
          active: true,
        },
      ],
    },
  },

  //////////// ROSTER SYSTEM

  {
    path: '/roster/profiles',
    name: 'apps-roster-profile-list',
    component: () => import('@/views/apps/roster/profile/list/ProfileList.vue'),

    meta: {
      permission: 'roster.profile.list',
      pageTitle: 'List Roster Profiles',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Roster Profiles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/profiles/pending',
    name: 'apps-roster-profile-list-pending',
    component: () => import('@/views/apps/roster/profile/list/pending/ProfilePendingList.vue'),

    meta: {
      permission: 'roster.profile.list',
      pageTitle: 'Pending Roster Profiles',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Roster Profiles',
          to: '/roster/profiles',
        },
        {
          text: 'Pending',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/profiles/:id/edit',
    name: 'apps-roster-profile-edit',
    component: () => import('@/views/apps/roster/profile/edit/ProfilesEdit'),

    meta: {
      permission: 'roster.profile.update',
      pageTitle: 'Edit Profile',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Roster Profiles',
          to: '/roster/profiles',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/courses/sections',
    name: 'apps-roster-course-section-list',
    component: () => import('@/views/apps/roster/courses/sections/list/CourseSectionList.vue'),
    meta: {
      permission: 'roster.cohort.list',
      pageTitle: 'All Course Sections',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Courses',
          to: '/roster/courses',
        },
        {
          text: 'Sections',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/courses/sections/upcoming',
    name: 'apps-roster-course-section-upcoming-list',
    component: () => import('@/views/apps/roster/courses/sections/list/CourseSectionList.vue'),
    meta: {
      permission: 'roster.cohort.upcoming.list',
      pageTitle: 'Upcoming Course Sections',
      kind: 'Upcoming',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Courses',
          to: '/roster/courses',
        },
        {
          text: 'Sections',
          to: '/roster/courses/sections',
        },
        {
          text: 'Upcoming',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/courses/sections/live',
    name: 'apps-roster-course-section-live-list',
    component: () => import('@/views/apps/roster/courses/sections/list/CourseSectionList.vue'),
    meta: {
      permission: 'roster.cohort.live.list',
      pageTitle: 'Live Course Sections',
      kind: 'Live',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Courses',
          to: '/roster/courses',
        },
        {
          text: 'Sections',
          to: '/roster/courses/sections',
        },
        {
          text: 'Live',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/courses/sections/:id',
    name: 'apps-roster-course-section-view',
    component: () => import('@/views/apps/roster/courses/sections/view/CourseSectionView.vue'),

    meta: {
      permission: 'roster.cohort.get',
      pageTitle: 'View Course Section',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Courses',
          to: '/roster/courses',
        },
        {
          text: 'Sections',
          to: '/roster/courses/sections',
        },
        {
          text: 'View Section',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/courses',
    name: 'apps-roster-course-list',
    component: () => import('@/views/apps/roster/courses/list/CourseList.vue'),

    meta: {
      permission: 'roster.course.list',
      pageTitle: 'List Courses',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Courses',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/courses/:id',
    name: 'apps-roster-course-view',
    component: () => import('@/views/apps/roster/courses/view/CourseView.vue'),

    meta: {
      permission: 'roster.course.get',
      pageTitle: 'View Course',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Courses',
          to: '/roster/courses',
        },
        {
          text: 'View Course',
          active: true,
        },
      ],
    },
  },

  {
    path: '/roster/pathways/cohorts',
    name: 'apps-roster-pathway-cohort-list',
    component: () => import('@/views/apps/roster/pathways/cohorts/list/PathwayCohortList.vue'),
    meta: {
      permission: 'roster.cohort.list',
      pageTitle: 'All Pathway Cohorts',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Pathways',
          to: '/roster/pathways',
        },
        {
          text: 'Cohorts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/pathways/cohorts/upcoming',
    name: 'apps-roster-pathway-cohort-upcoming-list',
    component: () => import('@/views/apps/roster/pathways/cohorts/list/PathwayCohortList.vue'),

    meta: {
      permission: 'roster.cohort.upcoming.list',
      pageTitle: 'Upcoming Pathway Cohorts',
      kind: 'Upcoming',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Pathways',
          to: '/roster/pathways',
        },
        {
          text: 'Cohorts',
          to: '/roster/pathways/cohorts',
        },
        {
          text: 'Upcoming',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/pathways/cohorts/live',
    name: 'apps-roster-pathway-cohort-live-list',
    component: () => import('@/views/apps/roster/pathways/cohorts/list/PathwayCohortList.vue'),
    meta: {
      permission: 'roster.cohort.live.list',
      pageTitle: 'Live Pathway Cohorts',
      kind: 'Live',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Pathways',
          to: '/roster/pathways',
        },
        {
          text: 'Cohorts',
          to: '/roster/pathways/cohorts',
        },
        {
          text: 'Live',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/pathways/cohorts/:id',
    name: 'apps-roster-pathway-cohort-view',
    component: () => import('@/views/apps/roster/pathways/cohorts/view/PathwayCohortView.vue'),

    meta: {
      permission: 'roster.cohort.get',
      pageTitle: 'View Pathway Cohort',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Pathways',
          to: '/roster/pathways',
        },
        {
          text: 'Cohorts',
          to: '/roster/pathways/cohorts',
        },
        {
          text: 'View Cohort',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/pathways',
    name: 'apps-roster-pathway-list',
    component: () => import('@/views/apps/roster/pathways/list/PathwayList'),

    meta: {
      permission: 'roster.pathway.list',
      pageTitle: 'List Pathways',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Pathways',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roster/pathways/:id',
    name: 'apps-roster-pathway-view',
    component: () => import('@/views/apps/roster/pathways/view/PathwayView.vue'),
    meta: {
      permission: 'roster.pathway.get',
      pageTitle: 'View Pathway',
      breadcrumb: [
        {
          text: 'Management',
          to: '/students',
        },
        {
          text: 'Pathways',
          to: '/roster/pathways',
        },
        {
          text: 'View Pathway',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/roster/calendars',
  //   name: 'apps-roster-calendar-list',
  //   component: () => import('@/views/apps/roster/calendars/list/CalendarList'),

  //   meta: { permission: 'roster.calendar.list', pageTitle: 'Calendars' },
  // },
];

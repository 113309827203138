import axios from '@axios';

const fetchPathwayList = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/pathways', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchPathway = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/pathways/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchPathwaySections = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/pathways/${id}/sections`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createPathway = (ctx, pathwayData) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v2/roster/pathways', pathwayData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updatePathway = (ctx, { pathwayId, pathwayData }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  if (pathwayData && pathwayData.id) {
    delete pathwayData.id;
  }
  if (pathwayData && pathwayData.createdAt) {
    delete pathwayData.createdAt;
  }
  if (pathwayData && pathwayData.updatedAt) {
    delete pathwayData.updatedAt;
  }

  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/pathways/${pathwayId}`, pathwayData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createCourseToPathway = (ctx, { pathwayId, courseIds }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/pathways/${pathwayId}/courses`, { courseIds })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const removeCourseFromPathway = (ctx, { pathwayId, courseIds }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/roster/pathways/${pathwayId}/courses`, { data: { courseIds } })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  fetchPathwayList,
  fetchPathway,
  fetchPathwaySections,
  createPathway,
  updatePathway,
  createCourseToPathway,
  removeCourseFromPathway,
};

import axios from '@axios';

const fetchCampusList = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/sites/campuses', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCampus = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/sites/campuses/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCampusWaitlist = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/sites/campuses/${id}/waitlist`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const deleteCampus = (ctx, { id }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/sites/campuses/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createCampus = (ctx, { campusData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v2/sites/campuses', campusData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateCampus = (ctx, { campusId, campusData }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  delete campusData.id;
  delete campusData.createdAt;
  delete campusData.updatedAt;

  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/sites/campuses/${campusId}`, campusData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  fetchCampusList,
  fetchCampus,
  fetchCampusWaitlist,
  deleteCampus,
  createCampus,
  updateCampus,
};

import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAuditLog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // delete queryParams.filter;

        axios
          .get('v1/audit/audit', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};

import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReports(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // delete queryParams.filter;

        axios
          .get('v1/reports', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReport(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/reports/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};

import Vue from 'vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { all } from '@awesome.me/kit-ca0366f03e/icons/modules';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
library.add(fab);
library.add(far);
library.add(all);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);

// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.min.css';

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';
Vue.component(FeatherIcon.name, FeatherIcon);

/* add lucide icons */
import LucideIcon from '@core/components/lucide-icon/LucideIcon';
Vue.component('lucide-icon', LucideIcon);

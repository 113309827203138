import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('v1/groups', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('v1/users', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id, ability = false, queryParams = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/users/${id}`, { params: { ability, ...queryParams } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('v1/users', userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    resetUserPassword(ctx, { userId, password = undefined }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/users/${userId}/password`, { password })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};

import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTemplates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // delete queryParams.filter;

        axios
          .get('v1/integration/monday/templates', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/integration/monday/templates/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTemplate(ctx, { id, templateData }) {
      delete templateData.id;
      delete templateData.updatedAt;
      delete templateData.createdAt;

      if (templateData.attachments) {
        templateData.attachments = templateData.attachments.map((ac) => {
          delete ac._id;
          delete ac.id;
          return ac;
        });
      }

      return new Promise((resolve, reject) => {
        axios
          .patch(`v1/integration/monday/templates/${id}`, templateData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createTemplate(ctx, templateData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/integration/monday/templates`, templateData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCohorts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // delete queryParams.filter;

        axios
          .get('v1/placement/cohorts', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCohort(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/placement/cohorts/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateCohort(ctx, { cohortId, cohortData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`placement/cohorts/${cohortId}`, cohortData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCohortSummary(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/placement/cohorts/${id}/summary`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addStudentsToCohort(ctx, { cohortId, studentIds, profileIds }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/placement/cohorts/${cohortId}/students`, { studentIds, profileIds })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeStudentsFromCohort(ctx, { cohortId, studentIds, profileIds }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`v1/placement/cohorts/${cohortId}/students`, { data: { studentIds, profileIds } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchProfiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // delete queryParams.filter;

        axios
          .get('v1/placement/profiles', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProfile(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v1/placement/profiles/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProfile(ctx, { id, profileData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`v1/placement/profiles/${id}`, profileData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};

import axios from '@axios';

const fetchClasses = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/classes', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchClass = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/classes/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const addClass = (ctx, { classData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v2/roster/classes', classData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const deleteClass = (ctx, { id }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/roster/classes/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateClass = (ctx, { id, classData }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  delete classData.id;
  delete classData.createdAt;
  delete classData.updatedAt;

  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/classes/${id}`, classData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  fetchClasses,
  fetchClass,
  addClass,
  deleteClass,
  updateClass,
};

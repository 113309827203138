import Vue from 'vue';
import axios from 'axios';
import { v4 } from 'uuid';

import pkg from '../../package.json';

/**
 * @returns {string}
 */
const getDeviceId = (LS_KEY = 'xdid') => {
  if (!localStorage.getItem(LS_KEY)) {
    localStorage.setItem(LS_KEY, v4());
  }

  return localStorage.getItem(LS_KEY);
};

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL || 'https://mgmt-api.prod.ipoint.services/',

  // timeout: 1000,
  headers: { 'x-device-id': getDeviceId(), 'x-client-version': `Mgmt-App/v${pkg.version}` },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;

import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import userStoreModule from '@/store/user/userStoreModule';
import reportStoreModule from '@/views/apps/report/reportStoreModule';
import jobStoreModule from '@/views/apps/job/jobStoreModule';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import studentStoreModule from '@/store/student/studentStoreModule';
import receivableStoreModule from '@/store/accounting/receivableStoreModule';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import siteStoreModule from '@/store/site/siteStoreModule';
import auditStoreModule from './audit/auditStoreModule';
import app from './utils/app';
import appConfig from './utils/app-config';
import verticalMenu from './utils/vertical-menu';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auditStoreModule,
    userStoreModule,
    reportStoreModule,
    jobStoreModule,
    rosterStoreModule,
    studentStoreModule,
    placementStoreModule,
    receivableStoreModule,
    siteStoreModule,
  },
  strict: process.env.DEV,
});

<template>
  <div style="display: contents">
    <transition name="cmd-menu-modal-transition">
      <div
        v-if="open"
        :ref="name"
        :data-name="name"
        class="cmd-menu"
        :class="{
          'cmd-menu-animations': useAnimations,
        }"
        @click="handleOverlayClick($event)"
      >
        <!--           'cmd-menu-searching': loadingAsyncData && !totalItemsCount, -->

        <div class="cmd-menu-modal">
          <div class="cmd-menu-content">
            <div class="cmd-menu-search-wrapper">
              <input
                ref="cmdMenuSearch"
                type="search"
                class="cmd-menu-search"
                :placeholder="placeholder"
                autocomplete="off"
                autofocus
                v-model="search"
                @keydown.esc="closeModal()"
                @keydown.enter.prevent="handleClick(selectedItem)"
                @keydown.down.prevent="handleArrowKeys($event, 'down')"
                @keydown.up.prevent="handleArrowKeys($event, 'up')"
                @keyup.down.prevent="stopArrowKeyInterval()"
                @keyup.up.prevent="stopArrowKeyInterval()"
              />
            </div>
            <div v-if="!loadingAsyncData && !totalItemsCount">
              <div class="cmd-menu-results-wrapper p-2">
                <p class="text-center text-muted mb-0">No results found</p>
              </div>
            </div>
            <div v-if="loadingAsyncData && !totalItemsCount" class="cmd-menu-results-wrapper">
              <div class="cmd-menu-results">
                <div class="cmd-menu-section-links">
                  <SkeletonTheme color="#252526" highlight="#353538">
                    <a style="height: 40px" v-for="n in 4" :key="n">
                      <div style="height: 30px; width: 30px; margin-right: 0.575rem">
                        <Skeleton circle class="circle-avatar" />
                      </div>
                      <div class="d-flex flex-column align-items-center">
                        <div style="height: 13px">
                          <Skeleton width="300px" height="13px" />
                        </div>
                      </div>
                    </a>
                  </SkeletonTheme>
                </div>
              </div>
            </div>
            <div v-if="totalItemsCount" class="cmd-menu-results-wrapper" ref="cmdMenuResults">
              <div v-for="(section, sIndex) in sections" :key="section.name" class="cmd-menu-results">
                <div v-if="section.name !== '_all'" class="cmd-menu-section-label">{{ section.name }}</div>
                <div class="cmd-menu-section-links">
                  <a
                    v-for="(item, iIndex) in section.items"
                    :key="item.id"
                    href="#"
                    :class="{ 'cmd-menu-results-selected': isItemSelected(sIndex, iIndex) }"
                    @click.prevent="handleClick(item)"
                    @mouseover="handleMouseOver(sIndex, iIndex)"
                    @mouseout="handleMouseOut"
                    v-if="$can(item.permission)"
                  >
                    <div class="icon" v-if="item.icon || item.faIcon || item.lcIcon">
                      <feather-icon v-if="item.icon" :icon="item.icon || 'CircleIcon'" class="w-100 h-100" />
                      <font-awesome-icon v-if="item.faIcon" :icon="item.faIcon || 'CircleIcon'" class="w-100 h-100" />
                      <lucide-icon v-if="item.lcIcon" :name="item.lcIcon || CircleDashed" class="w-100 h-100" />
                    </div>
                    <div class="avatar" v-if="item.avatar || item.avatarText">
                      <b-avatar
                        size="30"
                        :src="item.avatar"
                        :text="avatarText(item.avatarText || item.text)"
                        variant="light-info"
                        :rounded="item.avatarRounded || false"
                      />
                    </div>
                    <div>
                      <span>{{ item.text }}</span>
                    </div>
                    <div class="font-weight-light text-muted ml-50" v-if="item.description">
                      {{ item.description }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="cmd-menu-footer">
              <p><span class="dismiss-message" @click="closeModal()">ESC</span> to close</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="overlay-fade">
      <div class="cmd-menu-overlay" v-if="open" />
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import Fuse from 'fuse.js';
import { isNavLinkActive } from '@core/layouts/utils';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import studentStoreModule from '@/store/student/studentStoreModule';
import store from '@/store/index';
import { BCard, BAvatar } from 'bootstrap-vue';
import { SkeletonTheme, Skeleton } from 'vue-loading-skeleton/src/index.js';
import { avatarText } from '@/@core/utils/filter';

export default {
  components: {
    SkeletonTheme,
    Skeleton,
    BAvatar,
  },
  data() {
    return {
      name: 'GlobalSearch',
      placeholder: 'Type command or search...',
      open: false,

      debounceTimer: null,
      loadingAsyncData: false,
      totalItemsCount: 0,
      studentItems: [],
      courseItems: [],
      fuseOptions: { keys: ['text'], threshold: 0.3 },

      arrowKeyDown: false,
      arrowKeyInterval: null,
      hoveredItem: null,
      search: '',
      results: [],
      currentItem: null,
      scale: 1.0,
      useAnimations: true,
      selectedSectionIndex: 0,
      selectedCommandIndexWithinSection: 0,
      sections: [],
    };
  },
  props: {
    commandMenuActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedItem() {
      // Check if there's a valid selected section.
      if (this.selectedSectionIndex >= 0 && this.selectedSectionIndex < this.sections.length) {
        const selectedSection = this.sections[this.selectedSectionIndex];

        // Check if there's a valid selected command within the section.
        if (this.selectedCommandIndexWithinSection >= 0 && this.selectedCommandIndexWithinSection < selectedSection.items.length) {
          return selectedSection.items[this.selectedCommandIndexWithinSection];
        }
      }
      return null; // Return null if there's no valid selection.
    },
    commandItems() {
      return [
        {
          id: 'student_list',
          section: 'Students',
          text: 'View Students',
          lcIcon: 'Users',
          route: { name: 'apps-students-list' },
          permission: 'student.list',
        },
        {
          id: 'student_create',
          section: 'Students',
          text: 'Create New Student',
          icon: 'PlusCircleIcon',
          route: { path: '/students?create=true' },
          permission: 'student.create',
        },
        {
          id: 'course_list',
          section: 'Courses',
          text: 'View Courses',
          lcIcon: 'Book',
          route: { name: 'apps-roster-course-list' },
          permission: 'roster.course.list',
        },
        {
          id: 'course_sections',
          section: 'Courses',
          text: 'View Sections',
          faIcon: 'fa-regular fa-border-all',
          route: { name: 'apps-roster-course-section-list' },
          permission: 'roster.section.list',
        },
        // {
        //   id: 'course_create',
        //   section: 'Courses',
        //   text: 'Create New Section',
        //   icon: 'PlusCircleIcon',
        //   route: { name: 'apps-roster-course-list' },
        //   permission: 'roster.course.create',
        // },
        {
          id: 'pathway_list',
          section: 'Pathways',
          text: 'View Pathways',
          lcIcon: 'Library',
          route: { name: 'apps-roster-pathway-list' },
          permission: 'roster.pathway.list',
        },
        {
          id: 'pathway_cohorts',
          section: 'Pathways',
          text: 'View Cohorts',
          faIcon: 'fa-regular fa-border-all',
          route: { name: 'apps-roster-pathway-cohort-list' },
          permission: 'roster.cohort.list',
        },
        // {
        //   id: 'pathway_create',
        //   section: 'Pathways',
        //   text: 'Create New Cohort',
        //   icon: 'PlusCircleIcon',
        //   route: { name: 'apps-roster-pathway-list' },
        //   permission: 'roster.pathway.create',
        // },
        {
          id: 'list_profiles_all',
          section: 'Roster Profiles',
          text: 'View All Profiles',
          faIcon: 'fa-regular fa-address-card',
          route: { name: 'apps-roster-profile-list' },
          permission: 'roster.profile.list',
        },
        {
          id: 'list_profiles_pending',
          section: 'Roster Profiles',
          text: 'View Pending Profiles',
          faIcon: 'fa-regular fa-clock',
          route: { name: 'apps-roster-profile-list-pending' },
          permission: 'roster.profile.list',
        },
        {
          id: 'profile_create',
          section: 'Roster Profiles',
          text: 'Create New Profile',
          icon: 'PlusCircleIcon',
          route: { path: '/roster/profiles?create=true' },
          permission: 'roster.profile.create',
        },
      ];
    },
  },
  watch: {
    async search(value) {
      // Instantly use Fuse for filtering
      this.filterCommandItems(value);

      // Clear the existing timer if any
      clearTimeout(this.debounceTimer);

      this.loadingAsyncData = true; // Set loading state to true before async operation

      // Set a new timer
      this.debounceTimer = setTimeout(async () => {
        try {
          // Perform the debounced search
          await this.performDebouncedSearch(value);
        } finally {
          this.loadingAsyncData = false; // Reset loading state after async operation
        }
      }, 350); // 100ms delay
    },
    commandItems: {
      immediate: true,
      handler(actions) {
        this.filterCommandItems(this.search);
      },
    },
    open(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
        this.search = '';
        this.$nextTick(() => {
          this.$refs.cmdMenuSearch.focus();
          this.hoveredItem = null;
        });
      } else {
        document.body.style.overflow = '';
        this.resetSelection();
      }
      this.$emit('update:commandMenuActive', value);
    },
  },
  methods: {
    handleMouseOver(sectionIndex, itemIndex) {
      if (this.arrowKeyDown) return;

      // Update the selected indexes based on mouse hover
      this.selectedSectionIndex = sectionIndex;
      this.selectedCommandIndexWithinSection = itemIndex;

      // Deselect previously hovered item if any
      if (this.hoveredItem) {
        const { sectionIndex: prevSectionIndex, itemIndex: prevItemIndex } = this.hoveredItem;
        this.sections[prevSectionIndex].items[prevItemIndex].hovered = false;
      }

      // Select the current item and mark it as hovered
      this.hoveredItem = { sectionIndex, itemIndex };
      this.sections[sectionIndex].items[itemIndex].hovered = true;
    },
    handleMouseOut() {
      this.hoveredItem = null;
    },
    isItemSelected(sectionIndex, itemIndex) {
      return (
        (this.selectedSectionIndex === sectionIndex && this.selectedCommandIndexWithinSection === itemIndex) ||
        (this.hoveredItem && this.hoveredItem.sectionIndex === sectionIndex && this.hoveredItem.itemIndex === itemIndex)
      );
    },
    filterCommandItems(search) {
      // Use Fuse to filter command items instantly
      if (search) {
        const fuse = new Fuse(this.commandItems, this.fuseOptions);
        const actions = fuse.search(search).map((result) => result.item);
        this.results = actions;
      } else {
        this.results = this.commandItems;
      }
      this.totalItemsCount = this.results.length;
      this.updateSections();
    },
    async performDebouncedSearch(search) {
      if (search) {
        // Now Get and Add Students and Courses with debounced call

        const students = await this.filterStudents(search);
        const courses = await this.filterCourses(search);

        // Now Add Everything To Array, including instant Fuse results
        this.results = [...this.results, ...courses, ...students];
        this.totalItemsCount = this.results.length;
        this.updateSections();
      }
    },

    async filterStudents(search) {
      if (!this.$can('student.get')) return [];
      try {
        const response = await this.$store.dispatch('studentStoreModule/fetchStudents', {
          search,
        });
        const results = response.data.results;
        const students = results.map((student) => {
          return {
            id: student.id,
            section: 'Students',
            text: student.fullName,
            description: student.email,
            avatar: student.avatar,
            avatarText: student.fullName,
            route: { path: `/students/${student.id}` },
            permission: 'student.get',
          };
        });
        return students;
      } catch (error) {
        return [];
      }
    },
    async filterCourses(search) {
      if (!this.$can('roster.course.get')) return [];
      try {
        const response = await this.$store.dispatch('rosterStoreModule/fetchCourseList', {
          search,
        });
        const results = response.data.results;
        const courses = results.map((course) => {
          return {
            id: course.id,
            section: 'Courses',
            text: course.name,
            avatar: course.avatar,
            avatarText: course.name,
            avatarRounded: true,
            route: { path: `/roster/courses/${course.id}` },
            permission: 'roster.course.get',
          };
        });
        return courses;
      } catch (error) {
        return [];
      }
    },
    updateSections() {
      const items = this.results;
      const sectionedItems = items.reduce((sections, item) => {
        const section = item.section || '_all';
        if (!sections[section]) {
          sections[section] = [];
        }
        sections[section].push(item);
        return sections;
      }, {});
      this.sections = Object.keys(sectionedItems).map((name) => ({ name, items: sectionedItems[name] }));
      this.resetSelection();
    },

    handleArrowKeys(e, direction) {
      this.hoveredItem = null;

      const sectionsLength = this.sections.length;
      let currentSection = this.sections[this.selectedSectionIndex];
      let itemsLength = currentSection.items.length;

      if (direction === 'down') {
        this.selectedCommandIndexWithinSection++;
        if (this.selectedCommandIndexWithinSection >= itemsLength) {
          this.selectedCommandIndexWithinSection = 0;
          this.selectedSectionIndex = (this.selectedSectionIndex + 1) % sectionsLength;
        }
      } else if (direction === 'up') {
        this.selectedCommandIndexWithinSection--;
        if (this.selectedCommandIndexWithinSection < 0) {
          this.selectedSectionIndex = (this.selectedSectionIndex - 1 + sectionsLength) % sectionsLength;
          this.selectedCommandIndexWithinSection = this.sections[this.selectedSectionIndex].items.length - 1;
        }
      }

      this.$nextTick(() => {
        if (this.open) {
          this.scrollToSelectedCommand();
        }
      });

      // Start the interval only if it's not already running
      if (!this.arrowKeyInterval) {
        this.startArrowKeyInterval(e, direction);
      }

      e.preventDefault();
    },
    startArrowKeyInterval(e, direction) {
      // Set a flag to indicate that the arrow key is held down
      this.arrowKeyDown = true;

      // Start the interval to repeatedly call handleArrowKeys when the key is held down
      setTimeout(() => {
        // Check if the arrow key is still held down
        if (this.arrowKeyDown) {
          this.arrowKeyInterval = setInterval(() => {
            this.handleArrowKeys(e, direction);
          }, 10000); // Change the interval time to 200ms
        }
      }, 500); // Wait for 500ms before starting the interval
    },

    stopArrowKeyInterval() {
      // Stop the interval when the key is released
      clearInterval(this.arrowKeyInterval);
      this.arrowKeyInterval = null;

      // Reset the flag indicating that the arrow key is held down
      this.arrowKeyDown = false;
    },
    scrollToSelectedCommand() {
      const container = this.$refs.cmdMenuResults;
      const selectedCommand = this.$el.querySelector('.cmd-menu-results-selected');
      if (container && selectedCommand) {
        const containerRect = container.getBoundingClientRect();
        const selectedRect = selectedCommand.getBoundingClientRect();
        if (selectedRect.top < containerRect.top) {
          container.scrollTop -= containerRect.top - selectedRect.top + 100;
        } else if (selectedRect.bottom > containerRect.bottom) {
          container.scrollTop += selectedRect.bottom - containerRect.bottom + 100;
        }
      }
    },
    handleClick(item) {
      if (item.route) {
        if (item.route.name && this.$route.name !== item.route.name) {
          this.$router.push({ name: item.route.name });
        }
        if (item.route.path && this.$route.path !== item.route.path) {
          this.$router.push({ path: item.route.path });
        }
      }
      if (item.action) item.action();
      this.closeModal();
    },
    resetSelection() {
      this.selectedSectionIndex = 0;
      this.selectedCommandIndexWithinSection = 0;
    },
    openModal() {
      if (this.open) return;
      this.open = true;
    },
    closeModal() {
      if (!this.open) return;
      this.open = false;
    },
    handleOverlayClick(e) {
      if (e.target === this.$refs[this.name]) {
        this.closeModal();
      }
    },
  },
  mounted() {
    // if (this.name) {
    //   this.$root.$on(`openCommandMenu.${this.name}`, this.openOmnibar);
    //   this.$root.$on(`closeCommandMenu.${this.name}`, this.closeOmnibar);
    // } else {
    //   this.$root.$on('openCommandMenu', this.openOmnibar);
    //   this.$root.$on('closeCommandMenu', this.closeOmnibar);
    // }
    this.$root.$on('openGlobalSearch', this.openModal);
  },
  beforeDestroy() {
    this.$root.$off('openGlobalSearch', this.openModal);
  },
  setup() {
    return {
      isNavLinkActive,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.cmd-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5001 !important;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overscroll-behavior: contain;
  color: var(--text-muted);

  &-searching .cmd-menu-modal::after {
    background: linear-gradient(60deg, #0053ff, #0017b7, #0053ff, #009eff, #0017b7, #00b0ff);
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }

  &-modal {
    padding: 0;
    max-width: 600px;
    max-height: min(400px, calc(100vh - 20% - 2rem));
    width: 95%;
    top: 22.5%;
    left: 50%;
    transform: translate(-50%, -00%);
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    transition: transform 0.05s ease;
    will-change: transform;
    transform-origin: center;

    &:after {
      content: '';
      position: absolute;
      top: calc(-1 * var(--border-width));
      left: calc(-1 * var(--border-width));
      height: calc(100% + var(--border-width) * 2);
      width: calc(100% + var(--border-width) * 2);
      border-radius: var(--border-radius);
      z-index: -1;
      background: #292b38;
      box-shadow: 0 6px 30px 6px rgb(0 0 0 / 11%);
    }
  }

  &-content {
    display: grid;
    padding: 0;
    background: var(--background);
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: var(--border-radius);
    overflow: hidden;
    will-change: transform;
    transform-origin: center center;
  }

  &-header {
    border-bottom: 1px solid var(--border);
  }

  &-search {
    flex-grow: 1;
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    padding: 1rem 1rem 0.85rem 1rem;
    color: #dfe0e3;
    font-family: inherit;
    font-size: 1.25rem;
    line-height: 1.55;

    &-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid var(--border);
    }
  }

  &-tag {
    border-radius: var(--border-radius);
    padding: 0.5rem 0.8rem;
    background: var(--background-light);
    margin-right: -0.5rem;
    margin-left: 0.5rem;
    font-weight: 500;
  }

  &-results {
    padding: 0.5rem 0 0.6rem 0;

    &-wrapper {
      overflow-y: auto;
    }

    &-selected {
      outline: 0;
      background: var(--hover);
      color: var(--text-default);
    }

    a {
      height: 40px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0.6rem 1rem 0.6rem 0.55rem;
      box-sizing: border-box;
      color: var(--text-default);
      text-decoration: none;
      border-left: 2px solid transparent;
      border-radius: 0.5rem;
      transition: none !important;

      p {
        line-height: 1rem;
        margin: 0;
      }

      .icon,
      .avatar {
        display: flex;
        margin-right: 0.575rem;
        border-radius: 0 !important;
        background: transparent !important;
      }

      .icon {
        width: 16px;
        margin-left: 0.45rem;
      }

      &:focus,
      &:hover {
        outline: 0;
        color: var(--text-default);
      }
    }
  }

  &-footer {
    border-top: 1px solid var(--border);
    font-size: 0.8rem;
    color: var(--text-default);

    p {
      margin: 0;
      padding: 0.5rem 0.5rem;
    }

    .dismiss-message {
      background: var(--background-light);
      border-radius: 5px;
      padding: 0.2rem 0.5rem;
      margin-left: 0.5rem;
      font-family: monospace;
    }
  }

  input {
    &::-webkit-input-placeholder {
      color: var(--text-muted);
    }
    &::-moz-placeholder {
      color: var(--text-muted);
    }
    &::-ms-placeholder {
      color: var(--text-muted);
    }
    &::placeholder {
      color: var(--text-muted);
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &-section {
    &-links {
      display: grid;
      padding: 0 0.5rem;
      gap: 0.2rem;
    }
    &-label {
      font-size: 0.8rem;
      padding: 0 1rem 0.2rem 1rem;
      box-sizing: border-box;
      color: #9b9ba4;
    }
  }
}

.cmd-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(0px);
}

.overlay-fade-enter-active,
.overlay-fade-leave-active,
.cmd-menu-modal-transition-enter-active,
.cmd-menu-modal-transition-leave-active {
  transition: opacity 0.1s ease;

  &.cmd-menu-modal-transition-enter-active,
  &.cmd-menu-modal-transition-leave-active {
    transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.overlay-fade-enter,
.overlay-fade-leave-to,
.cmd-menu-modal-transition-enter,
.cmd-menu-modal-transition-leave-to {
  opacity: 0;

  &.cmd-menu-modal-transition-enter,
  &.cmd-menu-modal-transition-leave-to {
    transform: translateY(15px) scale(0.92);
  }
}

.overlay-fade-enter-to,
.cmd-menu-modal-transition-enter-to {
  opacity: 1;

  &.cmd-menu-modal-transition-enter-to {
    transform: translateY(0) scale(1);
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
<style>
.cmd-menu {
  .pu-skeleton {
    line-height: normal !important;
  }
  .circle-avatar {
    .pu-skeleton {
      width: 30px !important;
      height: 30px !important;
    }
  }
}
</style>
